.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  background: #171D39;
}
.emoji-picker-modal, .epr-preview, .epr-body, .epr-header, .epr-main, .EmojiPickerReact, .epr-dark-theme, .EmojiPickerReact.epr-dark-theme > *{
            
            
  --epr-bg-color: #262e58!important;
  --epr-category-label-bg-color: #1c2344!important;
  --epr-text-color: #007aeb !important;
  --epr-hover-bg-color: #4f8dde !important;

  --epr-focus-bg-color: #4f8dde !important;
  
  --epr-search-input-bg-color: #141a31 !important;

  --epr-picker-border-color: #151617 !important;

  --epr-search-input-bg-color-active: var(--epr-dark) !important;
  --epr-emoji-variation-indicator-color: #4f8dde !important;
  --epr-category-icon-active-color: #3271b7 !important;
  --epr-skin-tone-picker-menu-color: #22222295 !important;
}
/* .custom-toast-progress {
  background: #4DAEFF !important;
}

.custom-toast-container {
  background-color: #141A31 !important;
  color: white !important;
}

.Toastify__close-button {
  color: white !important;
  opacity: 0.5 !important;
  transition: all .2s !important;
}

.Toastify__close-button:hover {
  opacity: 1 !important;
} */